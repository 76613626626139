// Container widths
//
// Set the containers width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  // Single containers class with breakpoint max-widths
  .containers,
  // 100% wide containers at all breakpoints
  .containers-fluid {
    @include make-containers();
  }

  // Responsive containerss that are 100% wide until a breakpoint
  @each $breakpoint, $containers-max-width in $containers-max-widths {
    .containers-#{$breakpoint} {
      @extend .containers-fluid;
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      %responsive-containers-#{$breakpoint} {
        max-width: $containers-max-width;
      }

      // Extend each breakpoint which is smaller or equal to the current breakpoint
      $extend-breakpoint: true;

      @each $name, $width in $grid-breakpoints {
        @if ($extend-breakpoint) {
          .containers#{breakpoint-infix($name, $grid-breakpoints)} {
            @extend %responsive-containers-#{$breakpoint};
          }

          // Once the current breakpoint is reached, stop extending
          @if ($breakpoint == $name) {
            $extend-breakpoint: false;
          }
        }
      }
    }
  }
}
